<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fa fa-pen"></i> แก้ไขโปรโมชัน</h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body bg-white">
          <validation-observer v-slot="{handleSubmit}" ref="formValidator">
            <b-form @submit.prevent="handleSubmit(editHandler)">
              <form-promotion :item="item"/>
              <div class="float-right d-flex">
                <span><router-link :to="'/promotion'" class="nav-link text-underline"> กลับ</router-link></span>
                <!-- <b-button variant="secondary" @click="onBackHandler()"><i class="fa fa-arrow-left"></i> กลับ</b-button> -->
                <b-button variant="primary" type="submit" class="style_btn" v-if="permission.actionData.flag_update"><i class="fa fa-check"></i> บันทึก</b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DateUtils from '../../../util/dateUtils';
import formPromotion from './form.promotion';
export default {
  name: 'promotion-view-page-promotion-edit',
  data () {
    return {
      permission:{
        appSlug: 'promotion',
        actionData: {}
      },
      item: {},
      id: ''
    }
  },
  methods: {
    async editHandler () {
      const params = JSON.parse(JSON.stringify(this.item));
      params.order_start = DateUtils.dateFormat(params.order_start, "YYYY-MM-DD");
      params.order_end = DateUtils.dateFormat(params.order_end, "YYYY-MM-DD");
      params.delivery_start = DateUtils.dateFormat(params.delivery_start, "YYYY-MM-DD");
      params.delivery_end = DateUtils.dateFormat(params.delivery_end, "YYYY-MM-DD");
      params.order_time_start = DateUtils.dateFormat(params.order_time_start, "HH:mm");
      params.order_time_end = DateUtils.dateFormat(params.order_time_end, "HH:mm");
      params.delivery_time_start = DateUtils.dateFormat(params.delivery_time_start, "HH:mm");
      params.delivery_time_end = DateUtils.dateFormat(params.delivery_time_end, "HH:mm");
      const result = await this.HttpServices.putData(`/ab_promotion/${this.item.id}`, params);

      if(result&&result.status.code=="200"){
        this.AlertUtils.alertCallback('success', `บันทึกสำเร็จ`, ()=>{
          this.$router.push('/promotion');
        });
      }else{
        this.AlertUtils.alert('warning', result.status.message);
      }
    },
    onBackHandler(){
      this.$router.push("/promotion");
    },

    async getItem(){
      const result = await this.HttpServices.getData(`/ab_promotion/${this.id}`);
      if(result&&result.status.code=="200"){

        this.item = result.data.item;

        if(result.data.item.order_start != null){
          this.item.order_start = DateUtils.toDate(result.data.item.order_start)
          this.item.order_time_start = DateUtils.toDate(result.data.item.order_start)
        }

        if(result.data.item.order_end != null){
          this.item.order_end = DateUtils.toDate(result.data.item.order_end)
          this.item.order_time_end = DateUtils.toDate(result.data.item.order_end)
        }

        if(result.data.item.delivery_start != null){
          this.item.delivery_start = DateUtils.toDate(result.data.item.delivery_start)
          this.item.delivery_time_start = DateUtils.toDate(result.data.item.delivery_start)
        }

        if(result.data.item.delivery_end != null){
          this.item.delivery_end = DateUtils.toDate(result.data.item.delivery_end)
          this.item.delivery_time_end = DateUtils.toDate(result.data.item.delivery_end)
        }

      }else{
        this.AlertUtils.alertCallback('warning', `ไม่พบข้อมูล Promotion`, ()=>{
          this.$router.push('/promotion');
        });
      }
    },
  },
  components: {
    formPromotion
  },
  async mounted() {
    await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
    this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
    this.id = await this.SessionStorageUtils.getSession("page-promotion");
    await this.getItem();
  }
}
</script>
